<script>
    import { required, email } from "vuelidate/lib/validators";
    import Customers from '@/services/Customers';
    import validationMessages from '@/components/validations'

    export default {
        components:{
            validationMessages
        },

        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                demandOptions: ['delete'],
                demand: '',
                email: '',
                source: 'dashboard',
                showModal: false,
                tryingToEdit: false,
                submitted: false,
            };
        },
        validations: {
            email: {
                required,
                email
            },
            demand: {
                required,
            },

        },
        created() {
        },
        methods: {

            async addCustomerRequest(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        await Customers.addRequest({
                            email: this.email,
                            source: this.source,
                            demand: this.demand,
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            this.successmsg("Request has been saved");
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg("Request not saved")
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Request not saved")
                    }
                }
                this.tryingToEdit = false;
            },

            checked_primary(){
                this.primary_checked = this.primary == 'Y' ? true : false;
            },
            checked_verified(){
                this.verified_checked = this.verified == 'Y' ? true : false;
            },
            checked_mailing(){
                this.mailing_checked = this.mailing == 'Y' ? true : false;
            },
            refreshData() {
                this.$emit('onRefresh') //event from parent
            },
            closeModal() {
                this.showModal = false;
            },
        }
    };
</script>

<template>
    <b-modal v-model="showModal" id="add_request" title="Add Customer Request" title-class="font-18" centered>
        <form @submit.prevent="addCustomerRequest">

             <b-form-group label="Email" label-for="formrow-email-input" >
                <b-form-input
                   v-model.trim="email"
                   type="text"
                   id="email"
                   aria-describedby="email-feedback"
                   :class="{
                     'is-invalid': submitted && $v.email.$error,
                   }"
               >
               </b-form-input>
               <validationMessages v-if="submitted" :fieldName="'Email'" :validationName="$v.email"></validationMessages>
            </b-form-group>


            <b-form-group label="Demand" label-for="formrow-discount_type-input" class="mb-3">
                <b-form-select class="form-control" :class="{ 'is-invalid': submitted && $v.demand.$error}" :options="demandOptions" v-model="demand" ></b-form-select>
                <validationMessages v-if="submitted" :fieldName="'Demand'" :validationName="$v.demand"></validationMessages>
            </b-form-group>




            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addCustomerRequest" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
</template>