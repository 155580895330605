<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Customers from '@/services/Customers'
    import Swal from "sweetalert2";
    import store from "@/state/store";
    import ModalAddRequest from '@/components/modals/customers/modalAddRequest';


    export default {
        components: { Layout, PageHeader, ModalAddRequest},
        page: {
            title: "GPDR Requests",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "GPDR Requests",
                items: [
                    {
                        text: "Users",
                    },
                    {
                        text: "GPDR Requests",
                        active: true,
                        href: "/customers/customer_requests",
                    },
                ],
                csrf_token: localStorage.getItem('csrf_token'),
                isBusy: false,
                error: null,
                showModal:false,
                modalData:{},
                link_id:null,
                tableData: [],
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "timestamp",
                sortDesc: true,
                showHistory: false,
                fields: [
                    {
                        key: "first_name",
                        label: "First Name",
                        sortable: true,
                    },
                    {
                        key: "last_name",
                        label: "Last Name",
                        sortable: true,
                    },
                    {
                        key: "email",
                        label: "Email",
                        sortable: true,
                    },
                    {
                        key: "demand",
                        label: "Demand",
                        sortable: true,
                    },
                    {
                        key: "resolve",
                        label: "Resolve",
                        sortable: true,
                    },
                    {
                        key: "resolve_timestamp",
                        label: "Resolved At",
                        sortable: true,
                    },
                    {
                        key: "timestamp",
                        label: "Created At",
                        sortable: true,
                    },
                    "action",
                ],
            };
        },
        async created() {
            await this.getCustomerRequests()
        },
        methods: {
            callModalAddRequest() {
            this.$bvModal.show("add_request");
          },

            async getCustomerRequests(){
                let showHistory = this.showHistory == true ? 1 : 0;
                try {
                    this.toggleBusy();
                    const response = await Customers.getCustomersRequests('showHistory='+showHistory)
                    this.tableData = response.data.data
                    this.totalRows = this.tableData.length
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                    this.totalRows = 0;
                }

                // Set the initial value of totalRows based on the data length
                const initialTotalRows = this.tableData.length;

                // Set totalRows in the Vuex store
                this.$store.commit('setTotalRows', initialTotalRows);

                // Store totalRows in localStorage for global availability
                localStorage.setItem('totalRows', initialTotalRows);



            },

            resolveCustomersRequest(data, action){

                var title = '';
                var icon = '';
                var buttonText = '';
                if(action == 'Ignore'){
                    title = 'Ignore this customer request?';
                    icon = 'info';
                    buttonText = 'Ignore';
                }else if(action == 'Complete'){
                    title = 'Complete this customer request?';
                    icon = 'success';
                    buttonText = 'Complete';
                }else if(action == 'Delete'){
                    title = 'Delete this customer account?';
                    icon = 'warning';
                    buttonText = 'Delete Account';
                }

                Swal.fire({
                    title: title,
                    icon: icon,
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: buttonText,
                }).then((result) => {
                    if (result.value) {
                        try {
                            Customers.resolveCustomersRequest(data.request_id, {
                                csrf_token: this.csrf_token,
                                resolve: action,
                                user_id: data.user_id,
                                request_id: data.request_id,
                                demand: data.demand
                            })
                                .then((response) => {
                                    const res = response.data ? response.data : false;
                                    const error = response.data.error ? response.data.error : 'Failed';
                                    if(res){
                                        this.getCustomerRequests()
                                        if(action == 'Ignore'){
                                            Swal.fire("Ignored Request", "Request is successfully updated!", "success");
                                        }else if(action == 'Complete'){
                                            Swal.fire("Complete Request", "Request is successfully completed!", "success");
                                        }else if(action == 'Delete'){
                                            Swal.fire("Removed Account", "Request is successfully updated!", "success");
                                        }
                                    }else{
                                        Swal.fire("Fail!", error, "warning");
                                    }
                                }).catch(error => {
                                    this.error = error.response.data.error ? error.response.data.error : "";
                                    this.failedmsg(this.error)
                                })
                        }catch(error){
                            this.error = error.response.data.error ? error.response.data.error : "";
                            Swal.fire("Fail!", this.error, "warning");
                        }
                    }
                });
            },

            deleteCustomer(data){

                Swal.fire({
                    title: 'Action Delete customer',
                    icon: 'warning',
                    html: '<p>Please note that all data related with this customer will be deleted !!! </p> <h5> Are you sure you want to delete this account ? </h5>',
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: 'Delete',
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        return Customers.deleteCustomer(data.user_id)
                        .then((response) => {
                            return response;
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            Swal.fire("Fail!", this.error, "error");
                        })
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        const res = result.value.data ? result.value.data : false;
                        const error = result.value.data.error ? result.value.data.error : 'Failed';
                        if(res){
                            this.getCustomerRequests()
                            Swal.fire("Delete Account", "Customer is successfully deleted !", "success");
                        }else{
                            Swal.fire("Fail!", error, "warning");
                        }
                    }
                });
            },

            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

        },
        middleware: "authentication",
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6 col-md-2">
                                <h4 class="card-title">Total Requests: {{totalRows}}</h4>
                            </div>
                            <div class="col-6 col-md-8">

                                <b-form-checkbox-group>
                                    <div class="form-check form-switch form-switch-lg mb-3" >
                                        <input v-model="showHistory"
                                               type="checkbox"
                                               class="form-check-input"
                                               id="showHistory"
                                               @change="getCustomerRequests"
                                        />
                                        <label class="form-check-label" for="showHistory">Show History</label>
                                    </div>
                                </b-form-checkbox-group>
                            </div>


                             <div class="col-6 col-md-2  text-end">
                                 <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                                     @click="callModalAddRequest">
                                     <i class="mdi mdi-plus me-2"></i>Add Request
                                 </a>
                             </div>

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="mb-0">
                            <b-table
                                    :busy="isBusy"
                                    :items="tableData"
                                    :fields="fields"
                                    responsive
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(email)="data">
                                    <router-link title="View Customer" :to="{ path: `/customers/profile/${data.item.user_id}`}">
                                        {{data.item.email}}
                                    </router-link>
                                </template>

                                <template v-slot:cell(action)="data">

                                    <b-dropdown variant="primary" size="sm" v-if="!data.item.resolve_timestamp" >
                                        <template v-slot:button-content >
                                            Actions
                                            <i class="mdi mdi-chevron-down"></i>
                                        </template>
                                        <b-dropdown-item @click="resolveCustomersRequest(data.item, 'Ignore')">
                                            Ignore
                                        </b-dropdown-item>
                                        <b-dropdown-item v-if="data.item.demand == 'delete' " @click="deleteCustomer(data.item)">
                                            Delete
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 <ModalAddRequest  @onRefresh="getCustomerRequests();"></ModalAddRequest>

    </Layout>
</template>